import React from 'react';
import Plus from '../../svg/plus.svg';

const ButtonBook = ({ title, clickHendler }) => (
  <button
    className="button-default button-default__book"
    onClick={clickHendler}
  >
    <span className="button-default__wrapper">
      <span className="title">{title}</span>
      <span className="button-default__icons">
        <span className="icons-wrapper">
          <i className="button-icon">
            <Plus />
          </i>
          <i className="button-icon">
            <Plus />
          </i>
        </span>
      </span>
    </span>
  </button>
);

export default ButtonBook;
