import React from 'react';
import TrainingAbout from './TrainingAbout/TrainingAbout';
import TrainingAboutHeader from './TrainingAboutHeader/TrainingAboutHeader';
import TrainingInfo from './TrainingInfo/TrainingInfo';

const TrainingDefaultAbout = ({ dataAbout, dataShortDescription }) => {
  return (
    <>
      <TrainingAboutHeader dataAbout={dataAbout} />
      <section className="training-about">
        <div className="container-fluid">
          <div className="training-about__items">
            <TrainingAbout dataShortDescription={dataShortDescription} />
            <TrainingInfo dataShortDescription={dataShortDescription} />
          </div>
        </div>
      </section>
    </>
  );
};

export default TrainingDefaultAbout;
