import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/layout';
import Seo from '../components/seo';
import '../styles/style.sass';
import TrainingDefaultMain from '../components/Pages/TrainingDefault/TrainingDefaultMain/TrainingDefaultMain';

import dataTraining from '../db/dataTraining';
import TrainingDefaultModules from '../components/Pages/TrainingDefault/TrainingDefaultModules/TrainingDefaultModules';
import TrainingDefaultAbout from '../components/Pages/TrainingDefault/TrainingDefaultAbout/TrainingDefaultAbout';
// import TrainingDefaultSpeaker from '../components/Pages/TrainingDefault/TrainingDefaultSpeaker/TrainingDefaultSpeaker';
import TrainingDefaultCategories from '../components/Pages/TrainingDefault/TrainingDefaultCategories/TrainingDefaultCategories';
import TrainingDefaultVideo from '../components/Pages/TrainingDefault/TrainingDefaultVideo/TrainingDefaultVideo';
import Instagram from '../components/Layout/Instagram/Instagram';
import TrainingDefaultAnswers from '../components/Pages/TrainingDefault/TrainingDefaultAnswers/TrainingDefaultAnswers';
import TrainingDefaultBook from '../components/Pages/TrainingDefault/TrainingDefaultBook/TrainingDefaultBook';

const TrainingDefault = ({data}) => {
  // console.log(data.strapiTrenings.Faq)
  const trainingData = dataTraining;

  const crumbsNav = [
    {
      id: 1,
      title_ua: 'Головна',
      title_ru: 'Главная',
      title_en: 'Home',
      to: '/',
    },
    {
      id: 2,
      title_ua: 'Тренінги та Майстер-класи',
      title_ru: 'Тренинги и Мастер-классы',
      title_en: 'Trainings and Workshops',
      to: '/trainings/',
    },
  ];


  const { t } = useTranslation();
  return (
    <Layout whitwHeader={'active'}>
      <Seo title={t('Home')} />
      <TrainingDefaultMain
        mainData={data.strapiTrenings}
        crumbsNav={crumbsNav}
        dataMain={data.strapiTrenings}
      />
      <TrainingDefaultAbout 
        dataAbout={data.strapiTrenings.info} 
        dataShortDescription={data.strapiTrenings} 
      />
      <TrainingDefaultModules dataModules={data.strapiTrenings.Trening_module} />

      {/* <TrainingDefaultSpeaker dataSpeaker={trainingData.speaker} /> */}
      <TrainingDefaultCategories 
        dataCategory={trainingData.categories} 
        dataSpecialist={data.strapiTrenings.Categories_specialists}
      />
      <TrainingDefaultVideo dataPartners={data.strapiTrenings} />
      <TrainingDefaultAnswers dataAnswers={data.strapiTrenings} />
      <TrainingDefaultBook
        title={{
          title_ua: 'Записатись на тренінг',
          title_ru: 'Записаться на тренинг',
          title_en: 'Book a training',
        }}
      />
      <Instagram />
    </Layout>
  );
};

export default TrainingDefault;


export const query = graphql`
  query TrainingTemplateQuery($language: String!, $id: String!) {
    strapiTrenings(id: { eq: $id }) {

      image {
        localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
          }

        date
        title_ua
        title_ru
        title_en
        slug
        Seats_title_ua
        Seats_title_ru
        Seats_title_en
        Seats_description_ua
        Seats_description_ru
        Seats_description_en
        Module_title_ua
        Module_title_ru
        Module_title_en
        Module_description_ua
        Module_description_ru
        Module_description_en

        about_title_ua
        about_title_ru
        about_title_en
        about_description_ua
        about_description_ru
        about_description_en
        Data
        price_ua
        price_ru
        price_en
        location_ua
        location_ru
        location_en

        Trening_module {
          titile_ua
          titile_ru
          titile_en
          section_titile_ua
          section_titile_ru
          section_titile_en
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
          }
          description_ua
          description_ru
          description_en
        }
        Categories_specialists {
        title_ua
        title_ru
        title_en
        for_whom {
          title_ua
          title_ru
          title_en
          description_ua
          description_ru
          description_en
        }
      }
      image_cover {
        localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  layout: FULL_WIDTH
                )
              }
            }
        }
        video_link
        Faq {
          id
          title_ua
          title_ru
          title_en
          content_ua
          content_ru
          content_en
        }
        info {
        title_ua
        title_ru
        title_en
        description_ua
        description_ru
        description_en
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
