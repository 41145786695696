const dataTraining = {
  main: {
    date: '25.05.2021',
    title_ua:
      'Тренінг для косметологів Корекція периорбітальної зони "Очі вміють говорити"',
    title_ru:
      'Тренинг для косметологов Коррекция периорбитальной зоны "Глаза умеют говорить',
    title_en:
      'Training for cosmetologists Correction of the periorbital zone "Eyes can speak"',
  },
  about: {
    about_info: [
      {
        id: 1,
        title_ua: '25 місць',
        title_ru: '25 мест',
        title_en: '25 seats',
        content_ua:
          'Ми приділяємо увагу кожному студенту, тому кількість місць в групі обмежена',
        content_ru:
          'Мы уделяем внимание каждому студенту, поэтому количество мест в группе ограничено',
        content_en:
          'We pay attention to each student, so the number of places in the group is limited',
      },
      {
        id: 2,
        title_ua: '2 модуля',
        title_ru: '2 модуля',
        title_en: '2 modules',
        content_ua:
          'Тренінг складається із 2-х модулів: Теоретична частина та Практична частина',
        content_ru:
          'Тренинг состоит из 2-х модулей: Теоретическая часть и Практическая часть',
        content_en:
          'The training consists of 2 modules: Theoretical part and Practical part',
      },
      {
        id: 3,
        title_ua: 'Диплом',
        title_ru: 'Диплом',
        title_en: 'Certificate',
        content_ua:
          'Диплом  від національного тренера компаній Ipsen Farma, TEOXAN и O.X.X.Clinic',
        content_ru:
          'Диплом от национального тренера компаний Ipsen Farma, TEOXAN и O.X.X.Clinic',
        content_en:
          'Diploma from the national coach of Ipsen Farma, TEOXAN and O.X.X.Clinic',
      },
    ],
    about_description: {
      title_ua: 'Про тренінг',
      title_ru: 'О тренинге',
      title_en: 'About training',
    },
    about_details: {
      date: '06.03 / 14:00',
      price_ua: '8000 грн',
      price_ru: '8000 грн',
      price_en: '8000 uah',
      adress_ua:
        'Київ, Василя Тютюнника 51/1А, ЖК Французький квартал, O.X.X. Clinic',
      adress_ru:
        'Киев, Василия Тютюнника 51/1А, ЖК Французский квартал, O.X.X. Clinic',
      adress_en:
        'Kyiv, Vasyl Tyutyunnyka 51/1A, ZhK French Quarter, O.X.X. Clinic',
    },
  },
  modules: [
    {
      id: 1,
      title_ua: 'Перший модуль',
      title_ru: 'Первый модуль',
      title_en: 'First module',
      description_ua: 'Теоретична Частина',
      description_ru: 'Теоретическа часть',
      description_en: 'Theoretical Part',
    },
    {
      id: 2,
      title_ua: 'Другий модуль',
      title_ru: 'Второй модуль',
      title_en: 'Second module',
      description_ua: 'Практична частина',
      description_ru: 'Практическая часть',
      description_en: 'Practical Part',
    },
  ],
  speaker: {
    title: {
      title_ua: 'Спікер тренінгу',
      title_ru: 'Спикер тренинга',
      title_en: 'Training speaker',
    },
    title_ua: 'Оксана Кисса',
    title_ru: 'Оксана Кысса',
    title_en: 'Oksana Kyssa',
    content_ua:
      'Лікар дерматолог-венеролог, лікар естетичної медицини, Сертифікований тренер з навчання фахівців методиками введення ботулотоксином (Диспорт, Іпсен), філерів (Теосіал (Теоксан), рестилайн (Галдерма), коллагеностімуляторов (скульптра (Галдерма). Більше ніж 50 000 маніпуляцій за 25 років, тисячі вдячних пацієнтів.',
    content_ru:
      'Врач дерматолог-венеролог, Врач эстетической медицины, Сертифицированный тренер по обучению специалистов методикам введения ботулотоксинов (Диспорт, Ипсен), филлеров (Теосиал (Теоксан), рестилайн (Галдерма), коллагеностимуляторов (скульптра (Галдерма). Более 50 000 манипуляций за 25 лет, тысячи благодарных пациентов.',
    content_en:
      'Dermatologist-venereologist, Doctor of aesthetic medicine, Certified trainer for training specialists in methods of introducing botulinum toxins (Dysport, Ipsen), fillers (Teosial (Teoxan), Restylane (Galderma), collagen stimulants (sculptra (Galderma)). More than 50,000 manipulations in 25 years, thousands of grateful patients.',
  },
  categories: {
    title_ua: 'Категорії фахівців',
    title_ru: 'Категории специалистов',
    title_en: 'Categories of specialists',
    description_ua: 'Кому Підійде Тренінг?',
    description_ru: 'Кому подойдет Тренинг',
    description_en: 'Who is the Training for?',
    categories_items: [
      {
        id: 1,
        title_ua: 'Косметологам початківцям',
        title_ru: 'Начинающим косметологам',
        title_en: 'Beginner cosmetologists',
        description_ua:
          "Якщо ви тільки починаєте кар'єру в косметології та бажаєте засвоїти нову професію",
        description_ru:
          'Если вы только начинаете карьеру в косметологии и хотите освоить новую профессию',
        description_en:
          'If you are just starting a career in cosmetology and want to learn a new profession',
      },
      {
        id: 2,
        title_ua: 'Професійним косметологам',
        title_ru: 'Профессиональным косметологам',
        title_en: 'Professional cosmetologists',
        description_ua:
          'Якщо ви професійний косметолог, та бажаєте засвоїти нові техніки та бажаєте підвищити рівень кваліфікації',
        description_ru:
          'Если вы профессиональный косметолог, и хотите освоить новые техники и хотите повысить уровень квалификации',
        description_en:
          'If you are a professional cosmetologist, and you want to learn new techniques and want to improve your skills',
      },
      {
        id: 3,
        title_ua: 'Керівникам та власникам клінік',
        title_ru: 'Руководителям и владельцам клиник',
        title_en: 'Managers and owners of clinics',
        description_ua:
          'Якщо ви власник або керівник клініки та маєте необхідність впровадити нові послуги для пацієнтів',
        description_ru:
          'Если вы владелец или руководитель клиники и есть необходимость внедрить новые услуги для пациентов',
        description_en:
          'If you are the owner or manager of a clinic and need to introduce new services for patients',
      },
    ],
  },
  questions: [
    {
      id: 'q01',
      question_ua: 'З якого віку можна відвідувати клініку?',
      question_ru: 'С какого возраста можно посещать клинику?',
      question_en: 'At what age can you visit the clinic?',
      answer_ua:
        'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
      answer_ru:
        'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
      answer_en:
        'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
    },
    {
      id: 'q02',
      question_ua: 'З якого віку можна відвідувати клініку?',
      question_ru: 'С какого возраста можно посещать клинику?',
      question_en: 'At what age can you visit the clinic?',
      answer_ua:
        'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
      answer_ru:
        'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
      answer_en:
        'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
    },
    {
      id: 'q03',
      question_ua: 'З якого віку можна відвідувати клініку?',
      question_ru: 'С какого возраста можно посещать клинику?',
      question_en: 'At what age can you visit the clinic?',
      answer_ua:
        'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
      answer_ru:
        'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
      answer_en:
        'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
    },
    {
      id: 'q04',
      question_ua: 'З якого віку можна відвідувати клініку?',
      question_ru: 'С какого возраста можно посещать клинику?',
      question_en: 'At what age can you visit the clinic?',
      answer_ua:
        'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
      answer_ru:
        'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
      answer_en:
        'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
    },
    {
      id: 'q05',
      question_ua: 'З якого віку можна відвідувати клініку?',
      question_ru: 'С какого возраста можно посещать клинику?',
      question_en: 'At what age can you visit the clinic?',
      answer_ua:
        'Клініку можна відвідувати пацієнтам які досягли повноліття, та маяють паспорт громадянина України',
      answer_ru:
        'Клинику можно посещать пациентам которые достигли совершеннолетия и имеют паспорт гражданина Украины',
      answer_en:
        'he clinic can be visited by patients who have reached the age of majority and have a passport of a citizen of Ukraine',
    },
  ],
};

export default dataTraining;
