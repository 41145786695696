import React from 'react';
import useLanguage from '../../../../Hooks/useLanguage';

const TrainingAboutHeader = ({ dataAbout }) => {
  const langToggle = useLanguage;

  return (
    <section className="training-header">
      <div className="container-fluid">
        <div className="training-header--wrapper">
          {dataAbout.map((item, index) => {
            return (
              <div className="header-item" key={index}>
                <div className="header-item--content">
                  <h3 className="h3">
                    {langToggle(item.title_ua, item.title_ru, item.title_en)}
                  </h3>
                  <p className="text">
                    {langToggle(
                      item.description_ua,
                      item.description_ru,
                      item.description_en
                    )}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default TrainingAboutHeader;
