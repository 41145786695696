import React from 'react';
import CategoryContent from './CategoryContent/CategoryContent';
// import useLanguage from '../../../Hooks/useLanguage';
import CategoryHeader from './CategoryHeader/CategoryHeader';

const TrainingDefaultCategories = ({ dataCategory, dataSpecialist }) => {
  // const langToggle = useLanguage;

  return (
    <section className="training-categories">
      <div className="container-fluid">
        <CategoryHeader dataCategory={dataCategory} dataSpecialist={dataSpecialist}/>
        <CategoryContent dataSpecialist={dataSpecialist} />
      </div>
    </section>
  );
};

export default TrainingDefaultCategories;
