import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import useLanguage from '../../../Hooks/useLanguage';

const ReactMarkdown = require("react-markdown");

const TrainingDefaultModules = ({ dataModules }) => {
  const langToggle = useLanguage;

  return (
    <section className="training-modules">
      <div className="modules-wrapper">
        {dataModules.map((module, index) => {
          const image = getImage(module.image.localFile);
          return (
            <div className="module" key={index}>
              <div className="module-image--wrapper">
              <GatsbyImage 
                  image={image} 
                  className="module-image"
                  alt={langToggle(
                    module.titile_ua,
                    module.titile_ru,
                    module.titile_en
                  )}
                />
              </div>
              <div className="module-content--wrapper">
                <span className="module-title">
                  {langToggle(
                    module.section_titile_ua,
                    module.section_titile_ru,
                    module.section_titile_en
                  )}
                </span>
                <h2 className="modue-description">
                  {langToggle(
                    module.titile_ua,
                    module.titile_ru,
                    module.titile_en
                  )}
                </h2>
                <div className="mark-down--module">
                  <ReactMarkdown
                    source={langToggle(
                      module.description_ua,
                      module.description_ru,
                      module.description_en
                    )}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default TrainingDefaultModules;
