import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link } from 'gatsby-plugin-react-i18next';
import useLanguage from '../../../Hooks/useLanguage';
import {fromTrainingsSlugToUrl} from '../../../../utils/utils'

const TrainingDefaultMain = ({ crumbsNav, dataMain, mainData }) => {
  const langToggle = useLanguage;
  const image = getImage(mainData.image.localFile);

  return (
    <>
      <section id="scroller-top" className="mainScreen-variable scroller-top">
        <div className="mainScreen-variable__bg">
        <GatsbyImage 
          image={image} 
          className="mainScreen-second__image" 
          alt="tranings"
        />
        </div>
        <div className="mainScreen-variable__center">
          <span className="main-training__date">{dataMain.date}</span>
          <h1 className="h1">
            {langToggle(
              dataMain.title_ua,
              dataMain.title_ru,
              dataMain.title_en
            )}
          </h1>
        </div>
      </section>
      <div className="mainScreen-variable__bottom">
        <ul className="crumbsNav">
          {crumbsNav.map((item, index) => {
            return (
              <li className="crumbsNav-item" key={index}>
                <Link className="crumbsNav-item__link" to={item.to}>
                  {langToggle(item.title_ua, item.title_ru, item.title_en)}
                </Link>
              </li>
            );
          })}
             <li className={mainData ? "crumbsNav-item" : null}>
                <Link className="crumbsNav-item__link" to={mainData ? fromTrainingsSlugToUrl(mainData.slug) : null}>
                  {mainData ? langToggle(mainData.title_ua, mainData.title_ru, mainData.title_en) : null}
                </Link>
              </li>
        </ul>
      </div>
    </>
  );
};

export default TrainingDefaultMain;
