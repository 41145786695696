import React from 'react';
import useLanguage from '../../../Hooks/useLanguage';

const TrainingDefaultAnswers = ({ dataAnswers }) => {
  const langtoggle = useLanguage;
  const [activeItemID, setActiveItemID] = React.useState(0);

  return (
    <section className="training-answers">
     {dataAnswers.Faq.length === 0 ? '' :
      <header className="training-answers--header">
        <h2 className="section-description">
          {langtoggle(
            'Відповіді на Запитання',
            'Ответы на вопросы',
            'Answers on questions'
          )}
        </h2>
      </header>}
      <div className="training-answers--items accordion">
        {(dataAnswers.Faq || []).map((item, index) => {
          return (
            <div className="accordion-item" key={index} id={item?.id}>
              <div
                role="presentation"
                className={
                  activeItemID === item?.id ? 'question is-active' : 'question'
                }
                onClick={() =>
                  activeItemID === item?.id
                    ? setActiveItemID(0)
                    : setActiveItemID(item.id)
                }
              >
                <h3 className="question-title">
                  {langtoggle(
                    item?.title_ua,
                    item?.title_ru,
                    item?.title_en
                  )}
                </h3>
                <i className="accordion-icon">
                  <span className="icon-line icon-line__1"></span>
                  <span className="icon-line icon-line__2"></span>
                </i>
              </div>
              <div
                className={
                  activeItemID === item?.id ? 'answer is-active' : 'answer'
                }
              >
                <div className="answer-wrapper">
                  <p className="answer-title">
                    {langtoggle(item?.content_ua, item?.content_ru, item?.content_en)}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default TrainingDefaultAnswers;
