import React from 'react';
import useLanguage from '../../../../Hooks/useLanguage';

const CategoryContent = ({ dataSpecialist }) => {
  const langToggle = useLanguage;
  return (
    <div className="training-categories--content">
      {dataSpecialist.for_whom.map((item, index) => {
        const count = index + 1;

        return (
          <div className="training-categories--item" key={index}>
            <span className="training-categories--count">
              {'0' + count + ' /'}
            </span>
            <div className="categories-content--wrapper">
              <h3 className="training-categories--title">
                {langToggle(item.title_ua, item.title_ru, item.title_en)}
              </h3>
              <p className="training-categories--description">
                {langToggle(
                  item.description_ua,
                  item.description_ru,
                  item.description_en
                )}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CategoryContent;
