import React from 'react';
import useLanguage from '../../../../Hooks/useLanguage';

const TrainingInfo = ({ dataShortDescription }) => {
  const langToggle = useLanguage;

  return (
    <div className="training-about__item training-about__item--info">
      <ul className="info-list">
        <li className="info-list__item">
          <span className="info-list__title">
            {langToggle('Дата / Час', 'Дата / Время', 'Date / Time')}
          </span>
          <span className="info-list__value">
            {dataShortDescription.Data}
          </span>
        </li>
        <li className="info-list__item">
          <span className="info-list__title">
            {langToggle('Вартість', 'Цена', 'Price')}
          </span>
          <span className="info-list__value">
            {langToggle(
              dataShortDescription.price_ua,
              dataShortDescription.price_ru,
              dataShortDescription.price_en
            )}
          </span>
        </li>
        <li className="info-list__item">
          <span className="info-list__title">
            {langToggle('Локація', 'Локация', 'Location')}
          </span>
          <p className="info-list__adress">
            {langToggle(
              dataShortDescription.location_ua,
              dataShortDescription.location_ru,
              dataShortDescription.location_en
            )}
          </p>
        </li>
      </ul>
    </div>
  );
};

export default TrainingInfo;
