import React from 'react';
import useLanguage from '../../../../Hooks/useLanguage';

import ButtonBook from '../../../../UI/ButtonBook';

const TrainingAbout = ({ dataShortDescription }) => {
  const langToggle = useLanguage;

  function book() {
    console.log('Book Training Default');
  }

  return (
    <div className="training-about__item">
      <h2 className="h2">
        {langToggle(
          dataShortDescription.about_title_ua,
          dataShortDescription.about_title_ru,
          dataShortDescription.about_title_en
        )}
      </h2>
      <div className="mark-down--component">
        <p>
          {langToggle(
            dataShortDescription.about_description_ua,
            dataShortDescription.about_description_ru,
            dataShortDescription.about_description_en
          )}
        </p>
      
      </div>
      <ButtonBook
        clickHendler={book}
        title={langToggle(
          'Записатись на тренінг',
          'Записаться на тренинг',
          'Book a training'
        )}
      />
    </div>
  );
};

export default TrainingAbout;
