import React from 'react';
import useLanguage from '../../../Hooks/useLanguage';

const TrainingDefaultBook = ({ title }) => {
  const langToggle = useLanguage;

  return (
    <section className="training-book">
      <button className="training-book--button">
        <span className="pulse"></span>
        <span className="button-book--wrapper">
          <span className="button-book--title">
            {langToggle(title.title_ua, title.title_ru, title.title_en)}
          </span>
        </span>
      </button>
    </section>
  );
};

export default TrainingDefaultBook;
