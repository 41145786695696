import React from 'react';
import useLanguage from '../../../../Hooks/useLanguage';

const CategoryHeader = ({ dataCategory, dataSpecialist }) => {
  const langToggle = useLanguage;
  return (
    <header className="training-categories--header">
      <span className="section-title">
        {langToggle(
          dataCategory.title_ua,
          dataCategory.title_ru,
          dataCategory.title_en
        )}
      </span>
      <h2 className="section-description">
        {langToggle(
          dataSpecialist.title_ua,
          dataSpecialist.title_ru,
          dataSpecialist.title_en
        )}
      </h2>
    </header>
  );
};

export default CategoryHeader;
